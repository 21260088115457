@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #eef5fb;
}
input:focus{
  box-shadow :inset 0px 0px 3px 0px rgba(71,71,71,0.55) !important;
}
input{
  outline: none !important;
}
.set-width{
  width: 100% !important;
}
.remove-px{
  padding: 0 !important;
}
table thead th:first-child{
  border-radius: 15px 0 0 0;
}
table thead th:last-child{
  border-radius: 0 15px 0 0;
}
table tbody tr:last-child td:first-child{
  border-radius: 0 0 0 15px;
}
table tbody tr:last-child td:last-child{
  border-radius: 0 0 15px 0;
}
.flatpickr-month,.flatpickr-weekdays,.flatpickr-weekday,.flatpickr-monthDropdown-months{
  background-color: #599bfb !important;
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track {
  background: transparent; 
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: transparent; 
}

#lcjs-auto-flexbox{
  width: 80% !important;
  height: 600px !important;
  position: absolute !important;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
}
.ag-chart-no-data-overlay > div{
  display: none !important;
}
.coverage-chart .ag-chart-wrapper canvas{
  height: 420px !important;
}
/* @media(max-width:420){
  
} */
.ag-chart-wrapper canvas{
  width: 100% !important;
}
.table-data{
  text-overflow: ellipsis;
overflow: hidden;
white-space: normal;
width: 300px;
max-width: 300px;
}
.Office-card > span {
  border-right: 1px solid #ccc;
  width: 33.33%;
}
.Office-card > span:last-child{
  border: 0;
}

body.fs-12 *{
  font-size: 12px !important;
}

body.fs-15 *{
  font-size: 15px !important;
}

body.fs-18 *{
  font-size: 18px !important;
}
.view-card > div{
  width: 100%;
  margin: auto;
}
.view-card .chart{
  height: 73vh;
}
.view-card ul{
  width: auto;
}
.chart-data >div >div{
  display: flex;
  padding: 0;
  border: 0;
  gap: 12px;
}
.chart-data >div >div >div{
  width: auto;
  justify-content: flex-start;
}
.chart-data >div >div >div:nth-child(2) label{
  margin-right: 0;
}
.device-salves td{
  pointer-events: none;
}
.device-salves td:last-child{
  pointer-events: all;
}
@media (max-width:1300px) {
  .view-card > div{
    width: 100%;
  } 
}
@media (max-width:1100px) {
  .view-card ul{
    flex-wrap: wrap;
  }
}
@media (max-width:768px) {
  .chart-data >div >div{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .chart-data >div >div >div{
    flex-wrap: wrap;
  }
  .view-card .chart {
    height: 400px;
  }
}
@media (max-width:576px) {
  .view-card .chart {
    height: 300px;
  }
}
@media (max-width:425px) {
  .view-card .chart {
    height: 240px;
  }
}

body button.select-text-12{
  font-size: 12px !important;
}

body button.select-text-15{
  font-size: 15px !important;
}

body button.select-text-18{
  font-size: 18px !important;
}